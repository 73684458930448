@import "./hillview-common-mixin.scss";

/*============================
     Colors    
============================*/
$color-FFFFFF: #ffffff;
$color-000000: #000000;

.hill_view_main {
  font-family: "Poppins", sans-serif;
  // body {
  //   font-family: "Poppins", sans-serif;
  //   // height: 100dvh;
  // }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #545353;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #f7f5ef;
  }

  // *,
  // *::before,
  // *::after {
  //   margin: 0;
  //   padding: 0;
  //   box-sizing: border-box;
  // }

  li {
    background: none;
  }

  html {
    scroll-behavior: smooth;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  :focus {
    outline: none;
    outline: -webkit-focus-ring-color auto 0px;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  button:focus,
  textarea:focus,
  input:focus {
    outline: none;
  }

  li {
    list-style-type: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #00000080 !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
}

@media (min-width: 2560px) {
  .hill_view_main {
    font-size: 18px;
  }

  .my_container_2 {
    // max-width: 2226px;
    max-width: 2293.3px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1921px) {
  .hill_view_main {
    font-size: 16px;
  }
  .my_container_2 {
    // max-width: 1721px;
    max-width: 2293.3px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1920px) {
  .hill_view_main {
    font-size: 16px;
  }

  .my_container_2 {
    max-width: 1670px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1700px) {
  .hill_view_main {
    font-size: 13px;
  }

  .my_container_2 {
    max-width: 1619px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1620px) {
  .hill_view_main {
    font-size: 13px;
  }

  .my_container_2 {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1536px) {
  .hill_view_main {
    font-size: 11px;
  }

  .my_container_2 {
    max-width: 1356px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1440px) {
  .my_container_2 {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1366px) {
  .hill_view_main {
    font-size: 10px;
  }

  .my_container_2 {
    max-width: 1194px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1280px) {
  .hill_view_main {
    font-size: 10px;
  }

  .my_container_2 {
    max-width: 1113px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  .hill_view_main {
    font-size: 10px;
  }

  .my_container_2 {
    max-width: 1031px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1080px) {
  .hill_view_main {
    font-size: 10px;
  }

  .my_container_2 {
    max-width: 931px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 992px) {
  .hill_view_main {
    font-size: 10px;
  }

  .my_container_2 {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .my_container_2 {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

.error {
  font-size: 1.125em;
  margin-top: 0.25em;
  font-weight: 600;
  color: red;
  position: absolute;
  left: initial;
  top: 100%;

  &:first-letter {
    text-transform: uppercase;
  }
}

.section140 {
  padding: 8.75em 0 0;
}

.section_heading {
  @include section_heading40;
}

.section_subheading32 {
  @include section_subheading32;
}

.section_subheading30 {
  @include section_subheading30;
}

.section_subheading24 {
  @include section_subheading24;
}

.section_desc {
  @include section_desc20;
}

.orange {
  color: #dc7236;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .error {
    font-size: 1.3em;
  }

  .section140 {
    padding: 6em 0 0;
  }

  .section_heading {
    @include textStyles(2.4em, 1.4167, 0px);
    margin-bottom: 1em;
  }

  .section_subheading30 {
    @include textStyles(1.8em, 1.5, 0px);
  }

  .section_desc {
    @include textStyles(1.6em, 1.625, 0px);
    margin-bottom: 1.5em;
  }

  .section_subheading32 {
    @include textStyles(2em, 1.55, 0px);
  }

  .section_subheading24 {
    @include textStyles(1.8em, 1.778, 0px);
    margin-top: 1.1112em;
  }
}

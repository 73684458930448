// @import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
.enq_wrapper {
  position: fixed;
     right: -4.2em;
  top: 26.6875em;
  z-index: 3;
  transform: rotate(-90deg);
  //-webkit-writing-mode: vertical-rl;
  //writing-mode: vertical-rl;
  cursor: pointer;

  .btn {
    background-color: #dc7236;
    border-radius: 1em 1em 0em 0em;
    border: none;
    color: #ffffff;
    font-size: 1.5em;
    letter-spacing: 0px;
    line-height: 1.292;
    padding: 1.003em 0.73em;
    text-align: center;
    font-weight: 400;
    display: block;

    &:active {
      color: #ffffff;
      background-color: #dc7236;
      border: none;
    }

    &:focus-visible {
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.enquire_form.hill_view_main {
  margin: 0;
}

.enquire_form {

  // padding-right: 0 !important;
  .modal-dialog {
    width: 100%;
    max-width: 55.3%;
    padding-right: 0;
  }

  .modal-content {
    background-color: #f7f5ef;
    border: none;
    border-radius: 0;
    position: relative;
    padding: 5em 7.5em;

    .modal-header {
      justify-content: center;
      padding: 0;
      border: none;
      margin: 0 0 4.625em;

      .modal-title {
        color: #000000;
        font-size: 2.5em;
        font-family: "Libre Baskerville";
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.5;
        text-align: center;
      }

      .btn-close {
        border: 0;
        border-radius: 0;
        --bs-btn-close-bg: none;
        background-image: url("../../images/general/HillView/close_icon.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 0;
        margin: 0;
        position: absolute;
        right: 2.5em;
        top: 2.5em;
        opacity: 1;
        width: 1.679em;
        height: 1.5625em;

        &:focus-visible {
          outline: none;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .modal-body {
      padding: 0;

      .bg_logo {
        position: absolute;
        left: 50%;
        top: 28%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 9.5em;
      }

      .form_wrapper {
        .input_field {
          color: #000000;
          width: 100%;
          max-width: 100%;
          background-color: transparent;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #58595b;
          font-size: 1.5em;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: normal;
          height: 100%;
          min-height: 100%;
          position: relative;
          z-index: 3;

          &:focus-visible {
            outline: none;
            box-shadow: none;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &::placeholder {
            color: transparent;
          }

          &:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
          }
        }

        .form-floating {
          width: 100%;
          max-width: 100%;
          margin-bottom: 3.75em;
          position: relative;
        }

        .form-floating>label {
          color: #a7a7a7;
          font-size: 1.5em;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: normal;
          position: absolute;
          top: 0;
          padding: 0;

          &::after {
            background-color: #f7f5ef;
          }
        }

        .form-floating>.form-control {
          padding: 0 0 0.73em;
        }

        .form-floating>.form-control:focus~label,
        .form-floating>.form-control:not(:placeholder-shown)~label,
        .form-floating>.form-select~label {
          transform: scale(0.85) translateY(-2rem) translateX(0.15rem);
        }

        .comm_cta_sec1 {
          display: flex;
          justify-content: center;
          padding: 1.282em 0 2.5em;
        }

        .thank_msg {
          color: #776050;
          font-size: 1.25em;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: normal;
          text-align: center;
        }
      }
    }
  }
}

.hilView_error_form_msg {
  font-size: 1.125em;
  margin-top: 0.25em;
  font-weight: 600;
  color: red;
  position: absolute;
  left: initial;
  top: 100%;
}

@media (max-width: 992px) {
  .enquire_form {
    .modal-dialog {
      max-width: 80%;
    }
  }
}

@media (max-width: 767px) {
  .enq_wrapper {
    transform: rotate(0deg);
    writing-mode: initial;
    top: auto;
    right: initial;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;

    .btn {
      border-radius: 0;
      font-size: 2.2em;
      letter-spacing: 0px;
      line-height: normal;
      padding: 0.6em 0.5em;
      width: 100%;
      max-width: 100%;
    }
  }

  .enquire_form {
    .modal-dialog {
      max-width: 100%;
      margin: 0.8em auto;
      padding: 0 15px;
      height: calc(100vh - 0.8 * 2em);
      display: flex;
      align-items: center;
    }

    .modal-content {
      padding: 6em 1.6em;

      .modal-header {
        margin-bottom: 4em;

        .modal-title {
          font-size: 2.4em;
          letter-spacing: 0px;
          line-height: 1.5834;
        }

        .btn-close {
          right: 2.05em;
          top: 2.05em;
          width: 1.279em;
          height: 1.191em;
        }
      }

      .modal-body {
        .form_wrapper {
          .input_field {
            font-size: 1.5em;
            letter-spacing: 0px;
            line-height: 1.8667;
            min-height: 100%;
          }

          .form-floating>label {
            font-size: 1.5em;
            letter-spacing: 0px;
            line-height: 1.8667;
            padding: 0 0.4em;
          }

          .form-floating>.form-control {
            padding: 0 0 0.835em 0.4em;
          }

          .form-floating>.form-control:focus~label,
          .form-floating>.form-control:not(:placeholder-shown)~label,
          .form-floating>.form-select~label {
            transform: scale(0.85) translateY(-1.6rem) translateX(0.15rem);
          }
        }
      }
    }
  }
}
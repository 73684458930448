@import "../../HillViewCommon/hillview-common.scss";

.sticky_circle_wrap {
  height: 300dvh;
  .sticky_circle {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100dvh;
    display: flex;
    position: sticky;
    top: 0;
    overflow: hidden;

    .sticky_circle_element {
      clip-path: circle(25% at 50% 50%);
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;

      .animate_img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 0.4s;
        position: absolute;
        inset: 0%;
      }
    }

    .half_height_bg {
      z-index: -1;
      background-color: #f7f5ef;
      height: 50%;
      position: absolute;
      inset: auto 0% 0%;
    }
  }
}

@media (max-width: 767px) {
  .sticky_circle_wrap {
    height: 42dvh;
    margin-top: 2em;
    .sticky_circle {
      // height: 100dvh;
      height: 100%;
      .sticky_circle_element {
        clip-path: circle(35% at 50% 50%);
      }
    }
  }
}

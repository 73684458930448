.loc_container {
  padding-top: 6.25em;
  .loc_row {
    margin: 0 auto;
    justify-content: center;
    position: relative;
    column-gap: 2em;
    &::before {
      content: "";
      background-color: rgba(220, 114, 54, 0.56);
      width: 91.647%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .loc_col {
      padding: 0;
      margin-bottom: 6.25em;
      max-width: calc(25% - 1.75em);
      .loc_box {
        width: 100%;
        max-width: fit-content;
        margin: 0 auto;
        .head_wrapper {
          display: flex;
          align-items: flex-start;
          margin-bottom: 1.5em;
          .icon {
            max-width: 3.0625em;
            margin-right: 1em;
          }
        }
        .desc_wrapper {
          .section_desc {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .loc_container {
    .loc_row {
      .loc_col {
        max-width: calc(33.33% - 1.75em);
        flex: 0 0 33.33%;
        .loc_box {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .loc_container {
    .loc_row {
      .loc_col {
        flex: 0 0 auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .loc_container {
    padding-top: 1.6em;
    .loc_row {
      column-gap: 0;
      &::before {
        width: 100%;
      }
      .loc_col {
        max-width: 100%;
        margin-bottom: 4em;
        .loc_box {
          .head_wrapper {
            margin-bottom: 1.4em;
            .icon {
              max-width: 3.2em;
              margin-right: 1.4em;
            }
          }
        }
      }
    }
  }
}

.floorplan_wrapper {
  background-color: #f7f5ef;
  .plan_row {
    margin: 0 auto;
    // padding: 3.75em 0 0;
    padding: 8.75em 0 0;
    .plan_col {
      padding: 0;
      &:nth-child(1) {
        padding-right: 2.5625em;
      }
      &:nth-child(2) {
        padding-left: 0.5em;
      }
      .content_wrapper {
        .accordion-btn-wrapper {
          padding: 2.5em 1.25em 2.75em 0;
          border-bottom: 1px solid #dc7236;
          &:nth-child(2) {
            padding-top: 0;
          }
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .btn_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .acc_icon {
            width: 100%;
            max-width: 1.02em;
          }
        }
        .acc_items {
          display: none;
          margin-top: 1.25em;
          &.active {
            display: block;
          }
        }
        .section_subheading32 {
          text-transform: uppercase;
          margin-bottom: 0;
        }
        .comm_cta_sec1 {
          padding-top: 0.625em;
        }
      }

      .img_wrapper {
        background-color: #ffffff;
        position: relative;
        display: none;
        padding: 2.125em 0 2.125em 1.4075em;
        height: 100%;
        &.active {
          display: block;
        }
        .pdf_link {
          display: inline-block;
          position: absolute;
          right: 0;
          top: 0;
        }
        .download_icon {
          width: 100%;
          max-width: 6em;
        }
        .rera_no {
          color: #dc7236;
          font-size: 0.5em;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 1;
        }
        .plan_img {
          display: block;
          width: 100%;
          max-width: 100%;
          padding: 0 5.6em 0;
          height: 100%;
        }
        .floor_no {
          color: #707070;
          font-size: 1em;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: normal;
          padding: 0 0 2.125em 7.5em;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .floorplan_wrapper {
    .plan_row {
      .plan_col {
        flex: 0 0 100%;
        &:nth-child(1) {
          padding-right: 0;
        }
        &:nth-child(2) {
          padding-left: 0;
          margin-top: 4em;
        }
        .img_wrapper {
          display: block;
        }
        .content_wrapper {
          .comm_cta_sec1 {
            padding-top: 1.875em;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .floorplan_wrapper {
    .plan_row {
      // padding: 2em 0 0;
      padding: 6em 0 0;
      .plan_col {
        .content_wrapper {
          .acc_items {
            margin-top: 2.4em;
          }
          .accordion-btn-wrapper {
            padding: 2.5em 0 2.75em 0;
          }
          .btn_wrapper {
            padding-right: 2.4em;
            .acc_icon {
              max-width: 1.1em;
            }
          }
          .comm_cta_sec1 {
            padding-top: 3.2em;
          }
        }
        .img_wrapper {
          margin-top: 3.2em;
          padding: 1.321em 0 1em 0.916em;
          .rera_no {
            font-size: 0.7em;
            letter-spacing: 0px;
            line-height: normal;
          }
          .download_icon {
            max-width: 3.5em;
          }
          .plan_img {
            padding: 0 1.5em;
            min-height: 25.5em;
            object-fit: contain;
          }
        }
      }
    }
  }
}

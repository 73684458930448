.whatsapp_sec1 {
  position: fixed;
  bottom: 3.75em;
  right: 6.0625em;
  z-index: 5;
  .whatsapp_img {
    width: 100%;
    max-width: 6.313em;
  }
}

@media (max-width: 767px) {
  .whatsapp_sec1 {
    right: 2.4em;
    bottom: 7.3em;
    .whatsapp_img {
      max-width: 5em;
    }
  }
}

.walkthrough_container {
  .walkthrough_row {
    margin: 0 auto;
    column-gap: 5.1em;
    padding-top: 1.25em;
    .walkthrough_col {
      padding: 0;
      max-width: calc(33.333% - 3.53em);
      .walkthrough_box {
        .walkthrough_link {
          position: relative;
          display: inline-block;
          &:hover {
            .detail_box {
              opacity: 1;
              transition: opacity 0.5s ease-in-out;
            }
          }
        }
        .walkthrough_img {
          width: 100%;
          max-width: 100%;
        }
        .hill_logo {
          position: absolute;
          top: 1em;
          left: 1em;
          width: 100%;
          max-width: 3.533em;
        }
        .section_subheading24 {
          text-transform: uppercase;
        }
        .comm_cta_sec1 {
          margin-top: 1em;
        }
        .detail_box {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: rgba($color: #000000, $alpha: 0.6);
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
          .section_desc {
            color: #ffffff;
            font-weight: 500;
          }
          .icon360 {
            width: 100%;
            max-width: 6.55em;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .walkthrough_container {
    .walkthrough_row {
      column-gap: 0;
      .walkthrough_col {
        max-width: 100%;
        margin-top: 4em;
        &:nth-child(2n + 1) {
          padding-right: 1.5em;
        }
        &:nth-child(2n + 2) {
          padding-left: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .walkthrough_container {
    .walkthrough_row {
      padding-top: 0;
      .walkthrough_col {
        margin-top: 4em;
        &:nth-child(2n + 1) {
          padding-right: 0;
        }
        &:nth-child(2n + 2) {
          padding-left: 0;
        }
        .walkthrough_box {
          .comm_cta_sec1 {
            margin-top: 1.6em;
          }
          .walkthrough_link {
            width: 100%;
            max-width: 100%;
            &:hover {
              .detail_box {
                opacity: 0;
                display: none;
              }
            }
            .detail_box {
              display: none;
            }
          }
        }
      }
    }
  }
}

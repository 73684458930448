.navigation_slider_container {
  .slider_box {
    .section_subheading24 {
      text-transform: uppercase;
    }
    .slider_img {
      width: 100%;
      max-width: 100%;
    }
    .img_navigaion_slider_wrap{
      position: relative;
      .render_image_text_navigation_slider{
        position: absolute;
        bottom: 2%;
        right: 2%;
        font-size: 1em;
      }
    }
  }
  .arrows_wrapper {
    text-align: center;
    margin-top: 3.75em;
    .nav_left_arrow,
    .nav_right_arrow {
      width: 100%;
      max-width: 4.5em;
      object-fit: contain;
      cursor: pointer;
    }
    .nav_left_arrow {
      margin-right: 2.5em;
    }
  }
}

@media (max-width: 992px) {
  .home_sec4 {
    .content_wrapper {
      .section_desc {
        max-width: 38em;
      }
    }
  }
}

@media (max-width: 767px) {
  .navigation_slider_container {
    padding: 0 15px;
    .arrows_wrapper {
      .nav_left_arrow,
      .nav_right_arrow {
        max-width: 4.2em;
      }

      .nav_left_arrow {
        margin-right: 2.05em;
      }
    }
  }
}

/*============================
    Notes : 
      1) Any common css should be under your section for ex search talha and you'll find 
      Talha css starts here and Talha css ends here comment and my all css work will be in between these comments.

      2) Don't customize or change already mention css consult with the developer who created the property and look for change

      3) Don't put All you css here it's a common css file which means if you have a same section in multiple places so create css
      here and use across this project
============================*/

/*============================
    General css start Here    
============================*/

$color-FFFFFF: #ffffff;
$color-000000: #000000;
$color-A7A7A7: #a7a7a7;
$color-6B6B6B: #6b6b6b;
$color-F9F9F9: #f9f9f9;
$color-1E1E1E: #1e1e1e;
$color-DC7236: #dc7236;
$color-58595B: #58595b;
$color-707070: #707070;
$color-F9F9F9: #f9f9f9;

$heading-basker-regular: "Libre Baskerville";

body {
  font-family: "Poppins" !important;
  color: $color-000000;
}
video {
  width: 100% !important;
}
img {
  object-fit: cover;
  height: auto;
}
::-webkit-scrollbar {
  width: 8px;
  // background-color: $color-6B6B6B;
  background-color: #e1dada;
}
::-webkit-scrollbar-thumb {
  background-color: $color-1E1E1E;
  background-color: #9E9E9E;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
a {
  text-decoration: none !important;
}
:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

::-moz-focus-inner {
  border: 0;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
li {
  list-style-type: none;
}
[type="submit"],
button,
html [type="button"] {
  appearance: none;
  -webkit-appearance: none;
}
input [type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:target::-webkit-input-placeholder {
  color: #000 !important;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $color-000000 !important;
}
table {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*============================
    General css ends Here    
============================*/

/*============================
    Common container start Here    
============================*/
@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 2293.3px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 2426.65px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1921px) {
  .my_container {
    max-width: 2293.3px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 2426.65px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: 1720px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1820px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1900px) {
  body {
    font-size: 14px;
  }
  .my_container {
    max-width: 1361.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1640.335px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1536px) {
  body {
    font-size: 12.5px;
  }
  .my_container {
    max-width: 1361.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1448.835px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1440px) {
  .my_container {
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1365px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1367px) {
  body {
    font-size: 12px;
  }
  .my_container {
    max-width: 1223.708px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1294.854px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1146.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1069.625px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 834px) {
  body {
    font-size: 11.5px;
  }
  .my_container {
    max-width: 747.125px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 747.125px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 10px;
  }
}

/*============================
    Common container end Here    
============================*/

/*============================
    Form field css start Here    
============================*/

// .form_field_wrapper {
//   &:nth-child(n + 2) {
//     margin-top: 3.125em;
//   }
// }
.form_field_wrapper {
  position: relative;
  .select_box_wrapper {
    .select_box_el__control {
      background-color: transparent;
      padding: 1.263em 2.579em 0.8em 1em;
      cursor: pointer;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $color-000000;
      outline: none;
      box-shadow: none;
      text-transform: capitalize;
      // box-shadow: 0 0 0 1px $color-FFFFFF;
    }
    .select_box_el__placeholder {
      color: $color-000000;
      margin-left: 0;
      font-size: 1.188em;
    }
    .select_box_el__indicator-separator {
      display: none;
    }
    .select_box_el__indicator {
      display: none;
    }
    .select_box_el__single-value {
      color: $color-000000;
      margin-right: 3em;
      font-size: 1.188em;
    }
    .select_box_el__value-container {
      padding: 0;
    }
    .select_box_el__input-container {
      color: $color-000000;
      font-size: 1.188em;
    }
    // .select_box_el__input {
    //   margin-left: 1em;
    // }
    .select_box_el__menu {
      div {
        color: $color-000000;
        cursor: pointer;
        background-color: transparent;
        text-transform: capitalize;
      }
    }
  }
  .inputdownarrow {
    width: 100%;
    max-width: 1.125em;
    position: absolute;
    bottom: 45%;
    right: 2em;
    pointer-events: none;
  }
  .upload_label {
    display: flex;
    width: 100%;
    max-width: 70%;
    border: 2px dashed $color-707070;
    height: 4.688em;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 0.7em;
    border-radius: 0;
  }
}
.mobile_number_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1em 0;
  .country_code {
    position: relative;
    width: 100%;
    max-width: 25%;
    .countrycode_select {
      color: $color-000000;
      padding-left: 1em;
      padding-right: 1.5em;
      border-radius: 0;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      &::placeholder {
        color: $color-A7A7A7;
      }
    }
    select {
      -webkit-appearance: none;
      appearance: none;
      option {
        background-color: $color-FFFFFF !important;
        color: #000 !important;
      }
    }
    .dropwdown_icon {
      position: absolute;
      top: 2.1em;
      right: 10%;
      max-width: 1em;
    }
  }
  .mobile_number {
    // max-width: 80%;
    width: 100%;
    max-width: 73%;
  }
}
.form_input {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  font-size: 1.188em;
  line-height: 1.2em;
  padding: 1.263em 2.579em 0.8em 1em;
  background-color: transparent !important;
  border-bottom: 1px solid $color-000000;
  color: $color-000000;
  &::-webkit-input-placeholder {
    /* Edge */
    opacity: 0.7;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.7;
  }
  &::placeholder {
    opacity: 0.7;
  }
}
.form_input:-webkit-autofill,
.form_input:-webkit-autofill:hover,
.form_input:-webkit-autofill:focus,
.form_input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  color: $color-000000;
}
.upload_icon {
  margin-right: 0.75em;
}
select {
  color: $color-000000;
  text-transform: capitalize;
  option {
    color: $color-000000;
    padding: 1em;
    margin: 1em;
  }
  option:checked {
    background-color: #d7dbe0;
  }
}
// .Toastify__toast-body {
//   font-size: 1.3em;
//   font-family: "good-sans-regular";
// }
.error_message {
  position: absolute;
  bottom: -0.75em;
  font-size: 1em;
  color: red;
  padding-left: 1em;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

.error_zero_padding {
  padding-left: 0;
  margin-top: 1em;
}

.link_btn_nohover:hover {
  font-weight: normal;
}

.navigate_link {
  text-decoration: underline;
  color: $color-FFFFFF;
  &:hover {
    color: $color-FFFFFF;
  }
}

.form_submit_btn {
  display: block;
  width: 100%;
  font-size: 1.188em;
  height: 3.947em;
  // background-color: $color-FFFFFF;
  border: 1px solid $color-FFFFFF;
  border-radius: 1.792em;
  // color: $color-D46060;
  margin-top: 2.5em;
  background-color: transparent;
  color: $color-FFFFFF;
  position: relative;
  overflow: hidden;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: -1;
    background-color: $color-FFFFFF;
    transition: 0.3s all;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $color-FFFFFF;
    transition: 0.3s all;
  }
  &:hover::before,
  &:hover::after {
    height: 51%;
    border: none;
  }
  &:hover {
    // color: $color-D46060;
    border: none;
  }
}

/*============================
    Form field css ends Here    
============================*/

.project-button {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: fit-content;
  cursor: pointer;
  padding-bottom: 0.7em;
  color: $color-DC7236;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s color;
  font-size: 1.375em;
  font-weight: 600;
  &::after {
    content: "";
    position: absolute;
    bottom: 0em;
    left: 0;
    width: 100%;
    height: 2.5px;
    background-color: $color-DC7236;
    border: 0;
    outline: 0;
    transition: all 0.3s;
  }
  &:hover {
    &::after {
      width: 125%;
    }
  }
  .cta_right_arrow {
    box-sizing: content-box;
    width: 100%;
    max-width: 2.188em;
    padding-left: 3.375em;
  }
  &.apply_hover:hover {
    .cta_text {
      color: $color-DC7236;
    }
  }
  &.apply_hover:hover {
    &::after {
      background-color: $color-DC7236;
    }
  }
}

.navigation_wrapper {
  .nav_arrow {
    cursor: pointer;
    user-select: none;
  }
}

.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.section_heading {
  font-size: 2.5em;
  line-height: 1.5em;
  letter-spacing: 0px;
  font-family: "Baskerville";
  color: $color-000000;
}

.section_subheading {
  font-size: 1.875em;
  line-height: 1.8em;
  color: $color-000000;
  text-transform: capitalize;
}

.section_desc {
  font-size: 1.25em;
  line-height: 1.6em;
  color: $color-58595B;
}

@media (max-width: 1440px) {
  .section_heading {
    font-size: 5em;
  }
}

@media (max-width: 767px) {
  .enquire-now {
    position: relative;
    padding: 1em 0;
    .enquire-background {
      position: fixed;
      width: 100%;
      bottom: 0;
      z-index: 3;
    }
    .enquire-flex {
      display: flex;
      .enquire-whatsapp-icon {
        position: fixed;
        right: 0;
        bottom: 0.2em;
        z-index: 199;
      }
      .enquire-text-cta {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 0.8em 3.5em;
        font-size: 1.4em;
        text-transform: uppercase;
        font-weight: 600;
        color: $color-000000;
        z-index: 199;
        &::after {
          border-right: 1px solid $color-000000;
        }
      }
    }
  }
  .error_message {
    font-size: 1.3em;
  }
  .project-button {
    font-size: 1.4em;
  }
  .section_heading {
    font-size: 3.6em;
  }
  .section_subheading,
  .section_desc {
    font-size: 1.6em;
  }
  .section_desc {
    line-height: 1.625em;
  }
  .Toastify__toast-body {
    font-size: 1.6em;
  }
  .form_input {
    font-size: 1.4em;
  }
}

/* ====================
        MIXIN start here
======================*/

@mixin textStyles($fontSize, $lineHeight, $letterSpacing) {
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin fontStyles($fontFamily, $fontWeight) {
  font-family: $fontFamily, "Poppins";
  font-weight: $fontWeight;
}

/* ====================
        MIXIN end here
======================*/

// Talha common css starts here

// Talha common css ends here

// Tanmay common css starts here

// Tanmay common css ends here

// Sachin common css starts here

// Sachin common css ends here

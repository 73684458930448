@mixin textStyles($fontSize, $lineHeight, $letterSpacing) {
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin section_heading40 {
  color: #776050;
  @include textStyles(2.5em, 1.5, 0px);
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1em;
}

@mixin section_subheading32 {
    color: #000000;
    @include textStyles(2em, 0.969, 0px);
    font-family: "Poppins", serif;
    font-weight: 500;
    margin-bottom: 0.625em;
  }

  @mixin section_subheading30 {
    color: #000000;
    @include textStyles(1.875em, 1.5334, 0px);
    font-family: "Poppins", serif;
    font-weight: 500;
  }

@mixin section_subheading24 {
    color: #000000;
    @include textStyles(1.5em, 1.334, 0px);
    font-family: "Poppins", serif;
    font-weight: 400;
    margin-top: 1em;
  }

@mixin section_desc20 {
  color: #58595B;
  @include textStyles(1.25em, 1.6, 0px);
  font-family: "Poppins", serif;
  font-weight: 400;
  margin-bottom: 1.5em;
  &:last-child {
    margin-bottom: 0;
  }
}

@import "../../HillViewCommon/hillview-common.scss";

.hill_view_main {
  padding: 0;
  max-width: 100%;
  width: 100%;
  margin-top: 9.8em;
}

.hillview_sec1 {
  position: relative;
  .logo_wrapper {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin-top: 0.786em;
    .img_wrapper {
      position: relative;
      &::before {
        content: "";
        background-color: #ffffff;
        width: 1px;
        height: 60%;
        position: absolute;
        left: 11.9375em;
        top: 20%;
      }
    }
    .nagpal_logo {
      width: 100%;
      max-width: 9.4375em;
      margin-right: 6.33em;
    }
    .hill_view_logo {
      width: 100%;
      max-width: 4.3125em;
    }
  }
  .banner_slider_wrapper {
    position: relative;
    .swiper-pagination {
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-end;
      left: initial;
      right: 6.5em;
      bottom: 46.5%;
      transform: translateY(50%);
      width: 100%;
      max-width: fit-content;
    }
    .swiper-pagination-bullet {
      background: transparent;
      border-radius: 0;
      color: rgba($color: #ffffff, $alpha: 0.7);
      font-size: 1.5625em;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 1.4;
      width: fit-content;
      height: auto;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      border-bottom: 2px solid #dc7236;
    }

    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets
      .swiper-pagination-bullet {
      margin: 0 0 3.125em 0;
    }
    .banner_img {
      width: 100%;
      max-width: 100%;
      height: 100vh;
    }
    .content_wrapper {
      position: absolute;
      top: 50%;
      left: initial;
      transform: translate(0, -50%);
      z-index: 2;
      margin-left: 7.5em;
      .banner_desc {
        color: #ffffff;
        font-size: 1.875em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.2;
        margin-bottom: 0.9667em;
      }

      .banner_heading {
        color: #ffffff;
        font-size: 5.625em;
        font-weight: bold;
        font-family: "Gelasio", sans-serif;
        letter-spacing: 0px;
        line-height: 1.334;
      }
    }
    .banner_arrows {
      position: absolute;
      left: 8.75em;
      right: 8.75em;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left_arrow,
      .right_arrow {
        width: 100%;
        max-width: 4.5em;
        object-fit: contain;
        cursor: pointer;
        position: relative;
        z-index: 3;
      }
    }
  }
}

.sec3_hillView {
  // padding: 20.6875em 0 0;
  position: relative;
  .animation_wrapper {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    margin-top: 3.5em;
    .floorplan_circle {
      border-radius: 50%;
      width: 100%;
      max-width: 34.375em;
    }
  }
}

.hillview_sec4 {
  padding: 8.125em 0 0;
  background-color: #f7f5ef;
  .wrapper_aminites_heading_cta{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25em;
  }
  .content_wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 3.75em;
    .section_desc {
      width: 100%;
      max-width: 57.5em;
      margin-bottom: 0;
    }
    .comm_cta_sec1 {
      min-width: 18.9375em;
    }
  }
  .navigation_swiper {
    padding: 0 0 0 15px;
  }
}

.hillview_sec5 {
  padding: 8.75em 0;
  background-color: #f7f5ef;
  .section_heading {
    text-align: center;
  }
}

.hillview_sec6 {
  padding: 7.5em 0 0;
  .section_heading {
    text-align: center;
  }
}

.hillview_sec7 {
  padding: 6.25em 0 7.5em;
  .nagpal_map {
    width: 100%;
    max-width: 100%;
    height: 44.3125em;
  }
}

.hillview_sec8 {
  background-color: #f7f5ef;
  padding: 8.75em 0;
  .section_heading {
    text-align: center;
  }
  .comm_cta_sec1 {
    padding-top: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 2560px){
  .hill_view_main {
    margin-top: 10.8em;
  }
}
@media (max-width: 1900px){
  .hill_view_main {
    margin-top: 8.6em;
  }
}
@media (max-width: 1700px) {
  .hill_view_main {
      margin-top: 10.6em;
  }
}
@media (max-width: 1536px) {
  .hill_view_main {
      margin-top: 11.136em;
  }
}
@media (max-width: 1365px) {
  .hill_view_main {
      margin-top: 11.691em;
  }
}
@media (max-width: 1280px) {
  .hill_view_main {
      margin-top: 9.691em;
  }
}
@media (max-width: 834px) {
  .hill_view_main {
      margin-top: 11.3em;
  }
}

@media (max-width: 1536px) {
  .hillview_sec4 {
    .navigation_swiper {
      padding: 0;
    }
  }
}

@media (max-width: 1280px) {
  .hillview_sec4 {
    .navigation_swiper {
      padding: 0 0 0 15px;
    }
  }
}

@media (max-width: 992px) {
  .hillview_sec1 {
    .banner_slider_wrapper {
      .banner_img {
        height: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .hill_view_main {
    margin-top: 9.8em;
}
  .hillview_sec1 {
    .logo_wrapper {
      .img_wrapper {
        &::before {
          top: 19.5%;
          left: 7.666em;
          height: 62.5%;
        }
      }
      .nagpal_logo {
        max-width: 6.332em;
        margin-right: 2.65em;
      }
      .hill_view_logo {
        max-width: 3em;
      }
    }
    .banner_slider_wrapper {
      .swiper-pagination {
        display: none;
      }
      .banner_img {
        height: 100vh;
      }
      .content_wrapper {
        left: 20%;
        transform: translate(0, -50%);
        width: 100%;
        max-width: 100%;
        max-width: fit-content;
        margin-left: 0;
        .banner_desc {
          font-size: 1.8em;
          letter-spacing: 0px;
          line-height: normal;
          margin-bottom: 0.667em;
        }
        .banner_heading {
          font-size: 4em;
          letter-spacing: 0px;
          line-height: normal;
        }
      }
      .banner_arrows {
        left: 2em;
        right: 2em;
        .left_arrow,
        .right_arrow {
          max-width: 3em;
        }
      }
    }
  }

  .sec3_hillView {
    // padding: 16em 0 0;
    .animation_wrapper {
      margin-top: 4em;
      .floorplan_circle {
        max-width: 21.6em;
      }
    }
  }

  .hillview_sec4 {
    padding: 6em 0 0;
    .wrapper_aminites_heading_cta{
      margin-bottom: 1.6em;
    }
    .content_wrapper {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 4em;
    }
    .comm_cta_sec1 {
      margin-top: 4.09em;
      display: flex;
      justify-content: center;
    }
    .navigation_swiper {
      padding: 0;
    }
  }

  .hillview_sec5 {
    padding: 6em 0;
    .section_heading {
      text-align: left;
      margin-bottom: 0;
    }
  }

  .hillview_sec6 {
    padding: 4em 0 0;
  }

  .hillview_sec7 {
    padding: 4em 0 6em;
    .nagpal_map {
      height: 37.5em;
    }
  }

  .hillview_sec8 {
    padding: 6em 0;
    .comm_cta_sec1 {
      padding-top: 1.6em;
    }
  }
}
@media (max-width: 600px) {
  .hill_view_main {
    margin-top: 7.8em;
}
  .hill_view_main.my_container_2 {
      margin-top: 7.8em;
  }
}
